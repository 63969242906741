<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="dataTable"
            :items-per-page=itemsPerPage
            :loading=loadingTable
            :single-expand="true"
            :expanded.sync="expanded"
            :key="`list-price-rules-${tenantId}`"
            :search=search
            class="elevation-1"
            item-key="product_price_rule_id"
            show-expand
            dense
        >
            <template v-slot:top>
                <v-row 
                    align="center"
                    justify="center"
                >
                    <v-col cols=12 md=3  class='text-left'>
                        <v-toolbar-title class="ml-md-4">
                            <v-icon class="mb-1">mdi-cash-100</v-icon> REGLAS DE PRECIOS
                        </v-toolbar-title>
                    </v-col>
                    <v-col md="5">
                        <v-text-field
                            v-model=search
                            label=Buscar
                        />
                    </v-col>
                    <v-col md="2">
                        <v-select
                            v-model="state"
                            :items=status
                            item-value="state"
                            item-text="name"
                        />
                    </v-col>
                    <v-col md=2 class='text-center'>
                        <v-btn 
                            small
                            color = 'primary'
                            @click="editPriceRule()"
                        > 
                            <v-icon>mdi-plus</v-icon> Nueva regla
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.is_sale="{item}">
                {{item.is_sale != null ? item.is_sale : 'N' }}
            </template>
            <template v-slot:item.actions="{item}">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on" 
                            class="mx-2"
                            @click="editPriceRule(item)"
                            color='warning'
                        >mdi-pencil</v-icon>
                    </template>
                    <span>Editar regla</span>
                </v-tooltip>	
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on" 
                            class="mx-2"
                            @click="deletePriceRule(item)"
                            color="red"
                        >mdi-delete-forever</v-icon>
                    </template>
                    <span>Eliminar regla</span>
                </v-tooltip>	
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-row>
                        <v-col cols=12  class="my-2">
                            <div>
                                <v-alert
                                    type="success"
                                    dense
                                    class="mb-0 py-1"
                                >
                                    CONDICIONES
                                    <v-tooltip bottom >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn  
                                            style="float: right;"
                                                v-bind="attrs"
                                                v-on="on" 
                                                small
                                                elevation="2"
                                                icon 
                                                @click="setConditionForm({product_price_rule_id: item.product_price_rule_id})"
                                            >
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Nueva condición</span>
                                    </v-tooltip>               
                                </v-alert>
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">Nº</th>
                                                <th class="text-left">Seq</th>
                                                <th class="text-left">Condición</th>
                                                <th class="text-left">Operador</th>
                                                <th class="text-left">Producto</th>
                                                <th class="text-left">Categoría</th>
                                                <th class="text-left">Forma de pago</th>
                                                <th class="text-center" >Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(condition, x) in item.conditions"
                                                :key="x"
                                            >
                                                <td>{{ condition.product_price_rule_id }}</td>
                                                <td>
                                                    {{condition.product_price_cond_seq_id}}
                                                </td>
                                                <td>
                                                    {{condition.condition}}
                                                </td>
                                                <td>
                                                    {{condition.operator}}
                                                </td>
                                                <td>
                                                    {{condition.product_name}}
                                                </td>
                                                <td>
                                                    {{condition.product_category}}
                                                </td>
                                                <td>
                                                    {{condition.payment_method_type}}
                                                </td>
                                                <td class="text-center">
                                                    <v-tooltip bottom >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon
                                                                class="ml-2"
                                                                v-bind="attrs"
                                                                v-on="on" 
                                                                @click="setConditionForm(condition)"
                                                                color="warning"
                                                            >mdi-pencil</v-icon>
                                                        </template>
                                                        <span>Editiar condición</span>
                                                    </v-tooltip>     
                                                    <v-tooltip bottom >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon
                                                                class="ml-2"
                                                                v-bind="attrs"
                                                                v-on="on" 
                                                                @click="deletePriceRuleCondition(condition)"
                                                                color="red"
                                                            >mdi-delete-forever</v-icon>
                                                        </template>
                                                        <span>Eliminar condición</span>
                                                    </v-tooltip>      
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                            <div class="mt-3">
                                <v-alert
                                    type="success"
                                    dense
                                    class="mb-0 py-1"
                                >
                                    ACCIONES
                                    <v-tooltip bottom >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn  
                                            style="float: right;"
                                                v-bind="attrs"
                                                v-on="on" 
                                                small
                                                elevation="2"
                                                icon 
                                                @click="setActionForm({product_price_rule_id: item.product_price_rule_id})"
                                            >
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Nueva Acción</span>
                                    </v-tooltip>               
                                </v-alert>
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">Nº</th>
                                                <th class="text-left">Seq</th>
                                                <th class="text-left">Acción</th>
                                                <th class="text-left">Valor</th>
                                                <th class="text-center">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(action, x) in item.actions"
                                                :key="x"
                                            >
                                                <td>{{ action.product_price_rule_id }}</td>
                                                <td>{{ action.product_price_action_seq_id }}</td>
                                                <td>
                                                    {{action.action}}
                                                </td>
                                                <td>
                                                    {{action.amount}}
                                                </td>
                                                <td class="text-center">
                                                    <v-tooltip bottom >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon
                                                                class="ml-2"
                                                                v-bind="attrs"
                                                                v-on="on" 
                                                                @click="setActionForm(action)"
                                                                color="warning"
                                                            >mdi-pencil</v-icon>
                                                        </template>
                                                        <span>Editiar acción</span>
                                                    </v-tooltip>     
                                                    <v-tooltip bottom >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon
                                                                class="ml-2"
                                                                v-bind="attrs"
                                                                v-on="on" 
                                                                @click="deletePriceRuleAction(action)"
                                                                color="red"
                                                            >mdi-delete-forever</v-icon>
                                                        </template>
                                                        <span>Eliminar acción</span>
                                                    </v-tooltip>      
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>

                            </div>
                        </v-col>
                    </v-row>
                </td>
            </template>
        </v-data-table>
        <v-dialog
            v-model="dialogRule" 
            max-width="800"
            persistent
            background-color="white"
        >
            <v-col>
                <v-alert
                    type="info"
                    icon="mdi-dots-vertical"
                    dense
                > 
                    REGLA
                </v-alert>
                <v-col cols=12>
                    <v-form ref="form_rule">
                        <v-row>
                            <v-col cols=12 md="5">
                                <v-text-field
                                    v-model=priceRule.rule_name
                                    type='text'
                                    label="Nombre de la regla"
                                    dense
                                    class="text-center pb-2"
                                    :rules=required
                                ></v-text-field>
                            </v-col>
                            <v-col cols=12 md="5">
                                <v-text-field
                                    v-model=priceRule.description
                                    type='text'
                                    label="Descripción"
                                    dense
                                    class="text-center pb-2"
                                    :rules=required
                                ></v-text-field>
                            </v-col>
                            <v-col cols=12 md="2">
                                <v-select
                                    v-model=priceRule.is_sale
                                    :items=arrIsSale
                                    label="Regla de venta"
                                    item-value="name"
                                    dense
                                    item-text="name"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
                <v-col cols="12" class="text-center">
                    <v-btn 
                        color='success'
                        class="mx-1"
                        @click="storePriceRule"
                    > 
                        <v-icon>mdi-floppy</v-icon> Guardar 
                    </v-btn>
                    <v-btn 
                        class="mx-1"
                        color='default'
                        @click="closeDialogRule"
                    > 
                        <v-icon>mdi-cancel</v-icon> Cerrar 
                    </v-btn>
                </v-col>
                
            </v-col>
        </v-dialog>
        <v-dialog
            v-model="dialogPriceRuleCondition" 
            max-width="800"
            persistent
            background-color="white"
        >
            <v-col>
                <v-alert
                    type="info"
                    icon="mdi-dots-vertical"
                    dense
                > 
                    CONDICIÓN
                </v-alert>
                <v-col cols=12>
                    <v-form ref="form_price_rule_condition">
                        <v-row>
                            <v-col cols=12 md="3">
                                <v-select
                                    v-model="condition.input_param_enum_id"
                                    :items=condsPriceRule
                                    item-value="enum_id"
                                    dense
                                    label="Condición"
                                    item-text="description"
                                    :rules=required
                                    @change="setConditionForm(condition)"
                                ></v-select>
                            </v-col>
                            <v-col cols=12 md="2">
                                <v-select
                                    v-model="condition.operator_enum_id"
                                    :items=operators
                                    item-value="enum_id"
                                    dense
                                    label="Operador"
                                    :rules=required
                                    item-text="description"
                                ></v-select>
                            </v-col>
                            <v-col cols=12 md="7">
                                <v-autocomplete
                                    v-if="typeCondition!=null && typeCondition=='PRIP_PRODUCT_ID'"
                                    v-model=condition.product_id
                                    :items=products
                                    item-value="product_id"
                                    dense
                                    label="Productos"
                                    :rules=required
                                    item-text="internal_name"
                                ></v-autocomplete>
                                <v-autocomplete
                                    v-if="typeCondition!=null && typeCondition=='PRIP_PROD_CAT_ID'"
                                    v-model=condition.product_category_id
                                    :items=productsCategories
                                    item-value="product_category_id"
                                    dense
                                    label="Categorías de productos"
                                    :rules=required
                                    item-text="description"
                                ></v-autocomplete>
                                <v-autocomplete
                                    v-if="typeCondition!=null && typeCondition=='PRIP_PAY_METHOD'"
                                    v-model=condition.payment_method_type_id
                                    :items=paymentMethods
                                    item-value="payment_method_type_id"
                                    dense
                                    label="Métodos de pago"
                                    :rules=required
                                    item-text="description"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
                <v-col cols="12" class="text-center">
                    <v-btn 
                        color='success'
                        class="mx-1"
                        @click="storePriceRuleCondition"
                    > 
                        <v-icon>mdi-floppy</v-icon> Guardar 
                    </v-btn>
                    <v-btn 
                        class="mx-1"
                        color='default'
                        @click="closeDialogPriceRuleCondition"
                    > 
                        <v-icon>mdi-cancel</v-icon> Cerrar 
                    </v-btn>
                </v-col>
            </v-col>
        </v-dialog>
        <v-dialog
            v-model="dialogPriceRuleAction" 
            max-width="800"
            persistent
            background-color="white"
        >
            <v-col>
                <v-alert
                    type="info"
                    icon="mdi-dots-vertical"
                    dense
                > 
                    ACCIÓN
                </v-alert>
                <v-col cols=12>
                    <v-form ref="form_price_rule_action">
                        <v-row>
                            <v-col cols=12 md="7">
                                <v-select
                                    v-model="action.product_price_action_type_id"
                                    :items=typeActions
                                    item-value="product_price_action_type_id"
                                    dense
                                    label="Acción"
                                    item-text="description"
                                    :rules=required
                                ></v-select>
                            </v-col>
                            <v-col cols=12 md="5">
                                <v-text-field
                                    v-model=action.amount
                                    type='number'
                                    label="Monto"
                                    dense
                                    class="text-center pb-2"
                                    :rules=required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
                <v-col cols="12" class="text-center">
                    <v-btn 
                        color='success'
                        class="mx-1"
                        @click="storePriceRuleAction"
                    > 
                        <v-icon>mdi-floppy</v-icon> Guardar 
                    </v-btn>
                    <v-btn 
                        class="mx-1"
                        color='default'
                        @click="dialogPriceRuleAction=false"
                    > 
                        <v-icon>mdi-cancel</v-icon> Cerrar 
                    </v-btn>
                </v-col>
            </v-col>
        </v-dialog>
    </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex'
import Vue from 'vue'

export default {
    name: 'PriceRulesComponent',
    data: ()=>({
        headers: [
			{ text: 'Código', value: 'product_price_rule_id',align: 'start' },
			{ text: 'Nombre', value: 'rule_name',align: 'start'  },
            { text: 'Regla de venta', value: 'is_sale',align: 'center' },
            { text: 'Descripción', value: 'description',align: 'center' },
            { text: 'Reglas', value: 'data-table-expand',align: 'center' },
            { text: 'Acciones', value: 'actions',align: 'center' }
        ],
        arrIsSale:[{name:'S'},{name:'N'}],
        expanded:[],
        status:[
            {state: true, name: 'Activo'},
            {state: false, name: 'Inactivo'}
        ],
        condition:{
            product_price_rule_id: null,
            product_price_cond_seq_id: null,
            input_param_enum_id: null,
            operator_enum_id: null,
            cond_value: null
        },
        action:{
            product_price_rule_id: null,
            product_price_action_seq_id:null,
            product_price_action_type_id:null,
            amount:null
        },
        defaultCondition:{
            product_price_rule_id: null,
            product_price_cond_seq_id: null,
            input_param_enum_id: null,
            operator_enum_id: null,
            cond_value: null
        },
        priceRule:{
            product_price_rule_id:null,
            new_product_price_rule_id:null,
            rule_name:null,
            is_sale:null,
            description:null,
        },
        defaultPriceRule:{
            product_price_rule_id:null,
            new_product_price_rule_id:null,
            rule_name:null,
            is_sale:null,
            description:null,
        },
        state:true,
        search:null,
        dataTable:[],  
        condsPriceRule:[],
        operators:[] ,
        products:[],
        productsCategories:[],
        paymentMethods:[],
        typeActions:[],
        currentPage:1,
        itemsPerPage:20,
        dialog:false,
        dialogRule:false,
        typeCondition:null,
        dialogPriceRuleCondition:false,
        dialogPriceRuleAction:false,
        required:[v => !!v || 'El campo es requerido'],
        requiredMin:[
            v => !!v || 'El campo es requerido',
            v => (!!v && v > 0) || 'Debe escribir un número mayor a cero'
        ],
    }),
    computed:{
        
        ...mapState('master',['loadingTable','tenantId'])

    },
    methods:{

        ...mapMutations('master',['setUrl','setLoadingTable','setTitleToolbar']),

        ...mapActions('master',['requestApi','alertNotification']),

        getPricesRules(){
            
            this.setLoadingTable(true)
            this.setUrl('regla-precio') 
            this.dataTable=[]
            this.requestApi({
				method: 'GET',
				data: {
                    typeFetch: 'getPricesRules',
                    status: this.state
                }
			}).then(res =>{
                
                this.dataTable = res.data._embedded.regla_precio[0]
                this.condsPriceRule = res.data._embedded.regla_precio[1]
                this.operators = res.data._embedded.regla_precio[2]
                this.typeActions = res.data._embedded.regla_precio[3]
                   
			}).then(()=>{
                this.setLoadingTable(false)
            })

        },

        editPriceRule(priceRule){
            
            if(typeof priceRule !='undefined' && priceRule.is_sale == null) priceRule.is_sale = 'N'
            Object.assign(this.priceRule, priceRule)
            this.dialogRule= true

        },

        deletePriceRule(rulePrice){

            Vue.swal({
                html: "Está seguro de eliminar esta regla, se eliminaran las condiciones y acciones atadas a ella?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then( result => {

                if (result.isConfirmed) {
                   
                    this.setUrl('regla-precio')
                    this.requestApi({
                        method: 'DELETE',
                        data: {
                            typeDelete:'deletePriceRule',
                            ...rulePrice
                        }
                    }).then(res =>{
                        console.log(res)

                        this.alertNotification({
                            param:{
                                html: res.data.detail
                            }
                        })
                        let index = this.dataTable.indexOf(rulePrice)
                        this.dataTable.splice(index,1)
                        

                    }).then(()=>{

                        

                    })
        
                }
            })

        },

        closeDialogRule(){

            Object.assign(this.priceRule, this.defaultPriceRule)
            this.dialogRule= false
        },

        storePriceRule(){

            if(!this.$refs.form_rule.validate())
                return false

            this.setUrl('regla-precio')
            
            this.requestApi({
				method: 'POST',
				data: {
                    typeStore: 'storePriceRule',
                    ...this.priceRule
                }
			}).then(res =>{

                this.alertNotification({
                    param:{
                        html: res.data.res.msg
                    }
                })   
                this.getPricesRules()
                this.closeDialogRule()
			})

        },

        setConditionForm(condition){
            
            this.condition =condition
            this.typeCondition= condition.input_param_enum_id
            this.setLoadingTable(true)
            this.setUrl('regla-precio') 
          
            this.requestApi({
				method: 'GET',
				data: {
                    typeFetch: condition.input_param_enum_id,
                }
			}).then(res =>{
              
                if(condition.input_param_enum_id =='PRIP_PRODUCT_ID'){
                    this.products= res.data._embedded.regla_precio
                }else if(condition.input_param_enum_id == 'PRIP_PROD_CAT_ID'){
                    this.productsCategories= res.data._embedded.regla_precio
                }else{
                    this.paymentMethods = res.data._embedded.regla_precio
                }

                this.typeCondition = condition.input_param_enum_id
                this.condition = condition
                this.dialogPriceRuleCondition=true
                 
			}).then(()=>{
                this.setLoadingTable(false)
            })
        },  

        closeDialogPriceRuleCondition(){
            Object.assign(this.condition,this.defaultCondition)
            this.dialogPriceRuleCondition=false
        },

        storePriceRuleCondition(){
           
            if(!this.$refs.form_price_rule_condition.validate())
                return false
            
            this.setUrl('regla-precio')
            
            this.requestApi({
				method: 'POST',
				data: {
                    typeStore: 'storePriceRuleCondition',
                    ...this.condition
                }
			}).then(res =>{

                this.alertNotification({
                    param:{
                        html: res.data.res.msg
                    }
                })   
                this.getPricesRules()
                this.closeDialogRule()
			})


        },

        deletePriceRuleCondition(condition){
            
            Vue.swal({
                html: "Está seguro de eliminar esta condición?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then( result => {

                if (result.isConfirmed) {
                   
                    this.setUrl('regla-precio')
                    this.requestApi({
                        method: 'DELETE',
                        data: {
                            typeDelete:'deletePriceRuleCondition',
                            ...condition
                        }
                    }).then(res =>{
                        console.log(res)

                        this.alertNotification({
                            param:{
                                html: res.data.detail
                            }
                        })
                        this.getPricesRules()

                    }).then(()=>{

                        

                    })
        
                }
            })
        },

        setActionForm(action){

            this.action = action
            this.dialogPriceRuleAction=true
        },

        storePriceRuleAction(){

            if(!this.$refs.form_price_rule_action.validate())
                return false
            
            this.setUrl('regla-precio')
            
            this.requestApi({
				method: 'POST',
				data: {
                    typeStore: 'storePriceRuleAction',
                    ...this.action
                }
			}).then(res =>{

                this.alertNotification({
                    param:{
                        html: res.data.res.msg
                    }
                })   
                this.getPricesRules()
                this.closeDialogRule()
			})

        },

        deletePriceRuleAction(action){

            Vue.swal({
                html: "Está seguro de eliminar esta action?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then( result => {

                if (result.isConfirmed) {
                   
                    this.setUrl('regla-precio')
                    this.requestApi({
                        method: 'DELETE',
                        data: {
                            typeDelete:'deletePriceRuleAction',
                            ...action
                        }
                    }).then(res =>{
                        console.log(res)

                        this.alertNotification({
                            param:{
                                html: res.data.detail
                            }
                        })
                        this.getPricesRules()

                    }).then(()=>{

                        

                    })
        
                }
            })

        },

    },
    created(){

        this.setTitleToolbar('GESTIÓN GRUPO INNOFARM')
        this.getPricesRules()
    }
}
</script>

<style>
    .v-dialog{
        background: white;
    }
</style>